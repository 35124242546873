import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useParams } from 'react-router-dom'

import theme from '../../theme/theme';
import { useScreenSize } from '../../theme/breakpoints';

import { Group } from '../../services/pricing/groups';
import MindBodyEnrollmentWidget, { MindBodyEnrollmentWidgetData } from '../../components/widgets/mindbody/MindbodyEnrollmentWidget'
import { Typography } from '@mui/material';

export default function FunctionalStrengthTraining () {
    const isSmallScreen = useScreenSize('sm')
    const params = useParams()
    const menu = params.menuName as Group['identifier'] || 'memberships';

    const widgetId = {
        dataWidgetId: '5e957295e57'
    } as MindBodyEnrollmentWidgetData;

    return (
        <Box
            sx={{ 
                backgroundColor: 'primary.main',
                minHeight: '100vh',
                paddingTop: isSmallScreen ? '20px' : '75px',
                paddingBottom: theme.spacing(isSmallScreen ? 4 : 5)
            }}>
            <Container>
                <Typography 
                    variant='h2'
                    color='secondary.main' 
                    paddingLeft={theme.spacing(isSmallScreen ? 0 : 6)}
                    paddingBottom={theme.spacing(isSmallScreen ? 4 : 5)}
                    maxWidth={isSmallScreen ? '100%' : '75%'}
                    fontWeight={700}>
                    Functional Strength Training
                </Typography>
                <Typography variant='body1' paddingLeft={theme.spacing(isSmallScreen ? 0 : 6)} paddingBottom={theme.spacing(isSmallScreen ? 4 : 5)} color='secondary.main' maxWidth={isSmallScreen ? '100%' : '75%'} sx={{ whiteSpace: 'pre-line' }}>
                    {`Transform your strength and confidence with our 3-month Functional Strength Training Program. This program includes 24 small group personal training sessions and expert nutrition guidance to help you build functional muscle and achieve your goals. With tailored workout plans, 3 live nutrition coaching sessions, you are guaranteed to see and feel results in just three months!
                    
                    What will a day in this program be like?

                    Each workout will be tailored to build true strength and cause adaptation in your muscle fibers. This means we will be working in lower rep ranges and lifting heavier weights each week, following progressive overload tactics. All of our main lifts will be compound movements, meaning you are moving multiple joints in one exercise, versus isolated exercises. Examples of compound movements could be: squats, deadlifts, bench press, and overhead press. Examples of isolated movements could be: bicep curl, tricep extension, and hamstring curl.

                    Included in your program to maximize results, we will pair your training with: 3 remote, live 90-minute sessions with Jill Demers, a Certified Nutrition Therapy Practitioner. These include: recipe demos you can recreate at home and a group Q&A to refine your nutrition for optimal results.`}
                    </Typography>

                
                <MindBodyEnrollmentWidget { ...widgetId as MindBodyEnrollmentWidgetData} />
            </Container>
            
        </Box>
    )
}