import React, { ReactNode } from 'react';
import MuiThemeProvider from '@mui/material/styles/ThemeProvider';
import { ThemeProvider } from 'styled-components';
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';

import muiTheme from './theme/theme';
import Header from './sections/header/Header';
import Footer from './sections/footer/Footer';
import NotFound from './pages/not-found/NotFound';
import Home from './pages/home/Home';
import About from './pages/about/About';
import Shop from './pages/shop/Shop';
import Contact from './pages/contact/Contact';
import Pricing from './pages/pricing/Pricing';
import { PRICING_URL, SCHEDULE_URL } from './services/navigation';
import Schedule from './pages/schedule/Schedule';
import FunctionalStrengthTraining from './pages/pricing/FunctionalStrengthTraining';

function App() {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <MuiThemeProvider theme={muiTheme}>
          <ThemeProvider theme={muiTheme}>
            <header>
              <Header />
            </header>

            <main>
              <Routes>
                <Route path='/' element={<Home />} />

                <Route path='/about' element={<About />} />

                <Route path='/shop' element={<Shop />} />

                <Route path='/contact' element={<Contact />} />

                <Route path={`${PRICING_URL}/:menuName?`} element={<Pricing />} />

                <Route path='/functional-strength-training' element={<FunctionalStrengthTraining />} />

                <Route path={SCHEDULE_URL} element={<Schedule />} />

                <Route path='*' element={<NotFound />} />
              </Routes>
            </main>

            <footer style={{ width: '100%' }}>
              <Footer />
            </footer>
          </ThemeProvider>
        </MuiThemeProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
}

export default App;
